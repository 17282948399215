<template>
  <v-app>
    <div class="grey lighten-3 h-100 d-flex justify-center align-center">
      <v-card class="mb-8" style="width: 450px">
        <ValidationObserver v-slot="{handleSubmit}">
          <v-card-text class="px-8 py-10">
            <template v-if="!resetting">
              <h2 class="mb-7">{{ $t('account.forgotPassword') }}</h2>

              <div class="mt-auto mb-8">
                <v-tabs grow color="primary" v-model="tabIndex" @change="updateModel">
                  <v-tab> {{ $t('shared.mobile') }} </v-tab>
                  <v-tab> {{ $t('shared.email') }} </v-tab>
                </v-tabs>
              </div>

              <v-tabs-items v-model="tabIndex">
                <v-tab-item>
                  <mobile-input
                    v-model="model.mobile"
                    :code.sync="model.countryCode"
                    dir="ltr"
                    key="mobile"
                    :label="$t('shared.mobile')"
                    class="mb-2"
                    placeholder="1234567890"
                    :rules="{numeric: true, required: !model.email}"
                    @keydown.enter="handleSubmit(getRecoveryCode)"
                  />
                </v-tab-item>

                <v-tab-item>
                  <c-input
                    v-model="model.email"
                    dir="ltr"
                    key="email"
                    :label="$t('shared.email')"
                    class="mb-2"
                    placeholder="example@gmail.com"
                    :rules="{email: true, required: !model.mobile}"
                    @keydown.enter="handleSubmit(getRecoveryCode)"
                  />
                </v-tab-item>
              </v-tabs-items>

              <v-btn color="success" block elevation="1" @click="handleSubmit(getRecoveryCode)" :loading="progressing">
                {{ $t('account.resetPassword') }}
              </v-btn>
            </template>

            <ResetPassword v-else :value="model" @reset="resetting = false" />

            <v-btn block text color="secondary" class="mt-4" to="/login">
              {{ $t('account.loginViaPassword') }}
            </v-btn>
          </v-card-text>
        </ValidationObserver>
      </v-card>
    </div>
  </v-app>
</template>
<script>
import ResetPassword from './ResetPassword'
export default {
  name: 'ForgetPassword',
  components: {
    ResetPassword
  },
  data() {
    return {
      progressing: false,
      resetting: false,
      tabIndex: 0,
      model: {
        countryCode: null,
        email: null,
        mobile: null,
        countryCode: 'AU',
        sendViaEmail: false,
        sendViaSms: true
      }
    }
  },
  methods: {
    getRecoveryCode() {
      this.progressing = true
      return this.$api.accountPub
        .forgotPassword(this.model)
        .then(() => {
          this.$showSuccess(this.$t('account.recoveryCodeSendedToYourMobile'))
          this.resetting = true
        })
        .catch((err) => {
          this.$showError(err)
        })
        .finally(() => {
          this.progressing = false
        })
    },
    updateModel(tabIndex) {
      if (tabIndex) {
        this.model.mobile = null
        this.model.sendViaEmail = true
        this.model.sendViaSms = false
      } else {
        this.model.email = null
        this.model.sendViaEmail = false
        this.model.sendViaSms = true
      }
    }
  }
}
</script>
